import React, { Component } from 'react';

import { LandingPage } from './LandingPage';
import { Features } from './Features';
import { Stats } from './Stats';
import { StatsBI } from './StatsBI';
import Pricing from './Pricing';
import { FAQ } from './FAQ';
import { CTA } from './CTA';
import { CompanyList } from './CompanyList';

export class Home extends Component {
    
    render() {


        return (

            <div>
                <LandingPage />
                <Features />
                <CompanyList />
                <Stats  />
                <StatsBI  />
                <Pricing />
                <FAQ />
                <CTA />
            </div>

        );
    }
}