import React from 'react';


function SuccessNotification(message) {
    return (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          {message.message}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    )
}

function ErrorNotification(message) {
    return (
        <div className="alert alert-danger" role="alert">
            {message.message}
            <span type="button" className="close" data-bs-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></span>
        </div>
    )
}

function InfoNotification(message) {
    return (
        <div className="alert alert-info" role="alert">
            {message.message}
            <span type="button" className="close" data-bs-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></span>
        </div>
    )
}

const Notification = ({ success, message, sessionId }) => {
    if (!success && message === '') {
        return <div></div>;
    } else if (success && sessionId !== '') {
        return <SuccessNotification message={'Thank you for subscribing.  Please login again to refresh your updated profile and access.'} />;
    } else {
        return <ErrorNotification message={message} />;
    }
        
};

export default Notification;