import React from 'react';
import './Spinner.css';  // Assuming you have a CSS module for the spinner

function Error() {
    return (
        <section className="pt-12 pt-md-13">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <div style={{ textAlign: 'center' }}>
                            <h1>404</h1>
                            <p>Sorry, the page you're looking for cannot be found.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    );
}

export default Error;