import React, { useState, useEffect } from 'react';
import ExcelDownloadButton from './ExcelDownloadButton';
import { useParams } from 'react-router-dom';
import Spinner from '../General/Spinner';
import Error from '../General/Error';


function CompanyPage() {
    const { companyId } = useParams();
    const [companyDetails, setCompanyDetails] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchData() {

            if (!companyId) {
                console.error("No companyId provided.");
                setError(true);
                return;
            }

            try {
                const response = await fetch(`/api/company/${companyId}`);

                if (!response.ok) {
                    throw new Error(`Server responded with a ${response.status} status.`);
                }

                const data = await response.json();

                setCompanyDetails(data);

                if (data.imageContent && data.imageContent.trim() !== "") {
                    setImageSrc(`data:image/jpeg;base64,${data.imageContent}`);
                }
                else {
                    setImageSrc(null);
                }


            } catch (error) {
                
                console.error("There was an error fetching company details:", error);
                setError(true);

            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [companyId]); // The dependency array ensures the effect runs when `companyId` changes.

    if (error) return (
        <Error />
    )

    else if (loading || !companyDetails) return (
        <section className="pt-12 pt-md-13">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <Spinner />
                    </div>
                </div>
            </div>
        </section>
    )

    


    return (
        <section className="pt-12 pt-md-13">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md">

                        <div className="col-auto">
                            <div className="avatar avatar-xl">
                                {imageSrc && <img src={imageSrc} alt={`${companyDetails.ticker} image`} className="avatar-img rounded" />}
                            </div>

                            <h1 className="display-4 mb-2">

                                {companyDetails.name}
                            </h1>
                        </div>
                        <p className="fs-lg text-gray-700 mb-5 mb-md-0">
                            {companyDetails.ticker} | {companyDetails.cik}
                        </p>

                    </div>
                    <div className="col-auto">

                        <ExcelDownloadButton ticker={companyDetails.ticker} reportType="Annual" />

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">


                        <hr className="my-6 my-md-8 border-gray-300" />

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8">


                        <h3>
                            Company Description
                        </h3>


                        <p className="text-gray-800 mb-6 mb-md-8">
                            {companyDetails.description}
                        </p>




                    </div>

                    <div className="col-12 col-md-4">


                        <div className="card shadow-light-lg mb-5">
                            <div className="card-body">


                                <h4>
                                    Website
                                </h4>


                                <p className="fs-sm text-gray-800 mb-5">
                                    <a href={companyDetails.website} target="_blank" rel="noopener noreferrer">{companyDetails.website}</a>
                                </p>


                                <h4>
                                    SEC Filings
                                </h4>


                                <p className="fs-sm text-gray-800 mb-5">
                                    <a href={`https://www.sec.gov/edgar/browse/?CIK=${companyDetails.cik}`} target="_blank" rel="noopener noreferrer">
                                        {`https://www.sec.gov/edgar/browse/?CIK=${companyDetails.cik}`}
                                    </a>
                                </p>



                            </div>
                        </div>


                        

                    </div>

                    
                </div>
            </div>
        </section>

    );
}

export default CompanyPage;


