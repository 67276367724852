import React, { Component } from 'react';
import Photo1 from '../images/financial-1.jpg';
import Photo2 from '../images/msft_balance_sheet.PNG';

export class Stats extends Component {
    static displayName = Stats.name;



    render() {
        return (

            <section className="pt-8 pb-8 pt-md-8 bg-gray-200">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 col-lg-6 order-md-1">

                            <img src={Photo2} alt="Layers Stats" className="img-fluid mb-6 mb-md-0" />
                        </div>
                        <div className="col-12 col-md-7 col-lg-6 order-md-2">

                            <h2>
                                <span className="text-primary">Jump Start Your Analysis</span>
                            </h2>

                            <p className="fs-lg text-gray-700 mb-6">
                                No more copy-pasting and starting from scratch.  Immediately download any company's historical financial models directly into Excel. 
                            </p>

                            <div className="d-flex">
                                <div className="pe-5">
                                    <h3 className="mb-0">
                                        <span>5,000+</span>
                                    </h3>
                                    <p className="text-gray-700 mb-0">
                                        Public Company Models
                                    </p>
                                </div>
                                <div className="border-start border-gray-300"></div>
                                <div className="px-5">
                                    <h3 className="mb-0">
                                        <span>40+</span>
                                    </h3>
                                    <p className="text-gray-700 mb-0">
                                        XBRL Elements Tracked
                                    </p>
                                </div>
                                <div className="border-start border-gray-300"></div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


