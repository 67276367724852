import React, { Component } from 'react';

export class FAQ extends Component {
    static displayName = FAQ.name;



    render() {
        return (

            <section className="pt-10 bg-dark">
                <div className="container pt-8 pt-md-2">
                    <div className="row">
                        <div className="col-12 col-md-6">


                            <div className="d-flex">


                                <div className="badge badge-lg badge-rounded-circle bg-success">
                                    <span>?</span>
                                </div>

                                <div className="ms-5">


                                    <h4 className="text-white">
                                        What companies does my plan cover?
                                    </h4>


                                    <p className="text-muted mb-6 mb-md-8">
                                        Layers provides historical models for all public companies governed by the SEC.  Search your desired company in the Navigation Bar.
                                    </p>

                                </div>

                            </div>


                            

                        </div>
                        <div className="col-12 col-md-6">


                            <div className="d-flex">


                                <div className="badge badge-lg badge-rounded-circle bg-success">
                                    <span>?</span>
                                </div>

                                <div className="ms-5">


                                    <h4 className="text-white">
                                        How does Layers source model data?
                                    </h4>


                                    <p className="text-muted mb-6 mb-md-8">
                                        Layers sources GAAP taxonomy data from the Edgar SEC database of filings and APIs.
                                    </p>

                                </div>

                            </div>

                            

                        </div>
                        <div className="col-12 col-md-6">


                            <div className="d-flex">


                                <div className="badge badge-lg badge-rounded-circle bg-success">
                                    <span>?</span>
                                </div>

                                <div className="ms-5">


                                    <h4 className="text-white">
                                        I don't see a specific company or data element?
                                    </h4>


                                    <p className="text-muted mb-6 mb-md-8">
                                        Please get in touch to discuss any custom data elements or company specifics.
                                    </p>

                                </div>

                            </div>



                        </div>

                        <div className="col-12 col-md-6">


                            <div className="d-flex">


                                <div className="badge badge-lg badge-rounded-circle bg-success">
                                    <span>?</span>
                                </div>

                                <div className="ms-5">


                                    <h4 className="text-white">
                                        Can I request additional data, models, or specifics?
                                    </h4>


                                    <p className="text-muted mb-6 mb-md-8">
                                        Absolutely.  Please get in touch to discuss you or your company's needs.
                                    </p>

                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


