import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProfileNavBar from './auth0/ProfileNavBar';
import SearchComponent from './SearchComponent';
import LayersLogo from './images/layers_logo_800.png';
import './NavMenu.css';



const NavMenu = ({ user }) => {

    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-gray-800">
                <div className="container">

                    <img src={LayersLogo} alt="Layers Logo" className="icon icon-lg" id="layers-logo" />
                    <Link to="/" className="navbar-brand" id="layers-text">Layers</Link>



                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarCollapse">


                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-x"></i>
                        </button>


                        <ul className="navbar-nav ms-auto">


                            <SearchComponent />

                        </ul>


                        <ProfileNavBar user={user} />



                    </div>

                </div>
            </nav>
        </div>

    );
}

export default NavMenu;

