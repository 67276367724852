import React from 'react';

const Sidebar = () => (
    <div className="col-12 col-md-3">


        <div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">


            <div className="collapse d-md-block" id="sidenavCollapse">
                <div className="card-body">


                    <h6 className="fw-bold text-uppercase mb-3">
                        Account
                    </h6>

                    <ul className="card-list list text-gray-700 mb-0">
                        <li className="list-item active">
                            <a className="list-link text-reset" href="billing-plans-and-payment.html">
                                Plans & Payment
                            </a>
                        </li>

                    </ul>

                </div>
            </div>

        </div>

    </div>
);

export default Sidebar;