import React, { Component } from 'react';
import './NavMenu.css';
import { Link } from 'react-router-dom';
import LayersLogo from './images/layers_logo_800.png';


export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer className="py-8 py-md-11 bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-3">


                            <img src={LayersLogo} alt="Layers Logo" className="icon icon-lg" id="layers-logo" />
                            <Link to="/" className="navbar-brand">Layers.cloud</Link>


                            <p className="text-gray-700 mb-2">
                                Financial Analysis Products
                            </p>


                        </div>
                        <div className="col-6 col-md-4 col-lg-2">

                        </div>
                        <div className="col-6 col-md-4 col-lg-2">

                        </div>
                        <div className="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">


                            <h6 className="fw-bold text-uppercase text-gray-700">
                                Connect
                            </h6>


                            <ul className="list-unstyled text-muted mb-0">

                                <li className="mb-3">
                                    <a href="https://twitter.com/Exp_Layers" target="_blank" className="text-reset" rel="noreferrer">
                                        Twitter
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="https://www.linkedin.com/company/exponential-layers" target="_blank" className="text-reset" rel="noreferrer">
                                        LinkedIn
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="mailto:aleroy@layers.cloud?subject=Layers.cloud" className="text-reset" rel="noreferrer">
                                        Get In Touch
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="https://calendly.com/layers-cloud/30min" className="text-reset" rel="noreferrer">
                                        Schedule a Call
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <div className="col-6 col-md-4 col-lg-2">

                            <h6 className="fw-bold text-uppercase text-gray-700">
                                Resources
                            </h6>

                            <ul className="list-unstyled text-muted mb-0">
                                <li className="mb-3">
                                    <a href="https://research.layers.cloud/" target="_blank" className="text-reset" rel="noreferrer">
                                        Research & Blog
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="#!" className="text-reset">
                                        Terms of Service
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="#!" className="text-reset">
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}


