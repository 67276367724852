import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import ProfilePage from './components/Profile/ProfilePage'
import Error from './components/General/Error'
import CompanyPage from './components/CompanyPage/CompanyPage'
import "./landingkit/dist/assets/css/libs.bundle.css";
import "./landingkit/dist/assets/css/theme.bundle.css";


export default class App extends Component {
    render() {
        return (

            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}

                    <Route path="/profile" element={<ProfilePage />} />

                    <Route path="/company/:companyId" element={<CompanyPage />} />

                    <Route path="*" element={<Error />} />

                </Routes>

            </Layout>

        );
    }
}
