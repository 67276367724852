import React, { useState, useEffect } from 'react';

const ProfileNav = ({ user }) => {


    return (
        <div className="avatar ms-5 me-3">
            <img src={user.picture} alt={user.name} className="avatar-img rounded-circle" />
        </div>
    );
};


export default ProfileNav;