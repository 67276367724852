import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import RegisterModal from '../Modal/RegisterModal';

function extractFilename(contentDispositionValue) {
    if (!contentDispositionValue) return null;

    const filenameToken = "filename=";
    const startIndex = contentDispositionValue.indexOf(filenameToken);
    if (startIndex === -1) return null;

    const filename = contentDispositionValue.slice(startIndex + filenameToken.length).replace(/["']/g, "");
    return filename;
}

const ExcelDownloadButton = ({ ticker, reportType }) => {

    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);  // Hide modal
    };

    const processDownload = async (ticker, reportType, resourceId) => {
        const bodyData = {
            id: resourceId,
            ticker: ticker,
            reportType: reportType
        };

        try {
            const response = await fetch('/api/excel/download-excel-file', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData),
            });

            if (response.ok) {
                const blob = await response.blob();
                const contentDisposition = response.headers.get('Content-Disposition');
                const filename = extractFilename(contentDisposition) || 'defaultName.txt';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error("Failed to download the file.");
            }

        } catch (error) {
            console.error("Error during fetch:", error);
        }
    };

    const handleButtonClick = async () => {
        const resourceId = window.location.pathname.split('/').pop();

        if (ticker === "MSFT") {
            await processDownload(ticker, reportType, resourceId);
        }
        else if (!isAuthenticated) {
            setShowModal(true);
        }
        else {
            const idTokenClaims = await getIdTokenClaims();
            const appMetadata = idTokenClaims[`https://www.layers.cloud/hasSubscription`];

            if (appMetadata === undefined) {
                setShowModal(true);
            }
            else {
                await processDownload(ticker, reportType, resourceId);
            }
        }
    };

    return (
        <div>
        <button className="navbar-btn btn btn-sm btn-success" onClick={handleButtonClick}>
            Download Excel Models
        </button>

         {/* Pass the showModal state and handleCloseModal function as props to SignInModal */ }
            <RegisterModal show={showModal} onClose={handleCloseModal} />
        </div>
    );
}

export default ExcelDownloadButton;