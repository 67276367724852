import React, { Component } from 'react';
import LoginButton from '../auth0/LoginButton';

export class CTA extends Component {
    static displayName = CTA.name;



    render() {
        return (

            <section className="py-8 py-md-8 bg-dark">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">


                            <span className="badge rounded-pill bg-gray-700-soft mb-4">
                                <span className="h6 fw-bold text-uppercase">Get started</span>
                            </span>


                            <h1 className="display-4 text-white">
                                Start Your Analysis
                            </h1>


                            <p className="fs-lg text-muted mb-6 mb-md-8">
                                Analyze the Entire Suite of SEC Filing Data with Interactive Power BI Dashboards and Excel Models.
                            </p>

                            <p className="fs-lg text-muted mb-6 mb-md-8">
                                Create a Free Account using the below link and get access to all Beta Resources.
                            </p>

                            <LoginButton />

                            

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


