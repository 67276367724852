import React, { useState, useRef, useCallback } from 'react';
import './css/Home.css';
import './SearchComponent.css';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';


const SearchComponent = () => {

    const [input, setInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const navigate = useNavigate();
    const listEl = useRef(null);

    React.useEffect(() => {
        if (listEl.current) {
            listEl.current.scrollIntoView({
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [highlightedIndex]);


    const useOutsideAlerter = (ref, onOutsideClick) => {
        React.useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, onOutsideClick]);
    };

    // Ref for the whole component to check for outside clicks
    const componentRef = useRef(null);

    useOutsideAlerter(componentRef, () => {
        // Collapse suggestions when click is outside of component
        setSuggestions([]);
    });

    // Potential addition: cancel pending fetch when input is cleared or component is unmounted
    const abortController = useRef(new AbortController());

    const fetchSuggestions = useCallback(debounce(async (query) => {
        try {
            abortController.current.abort(); // Cancel previous fetch
            abortController.current = new AbortController();
            const response = await fetch(`/api/search?q=${query}`, { signal: abortController.current.signal });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setSuggestions(data);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error("Failed to fetch suggestions:", error);
                setSuggestions([]);
            }
        }
    }, 300), []); // Dependencies are empty because debounce creates a stable function

    const handleChange = (e) => {
        const value = e.target.value;
        setInput(value);
        setHighlightedIndex(-1);

        if (value.length > 0) {
            fetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    };

    const handleSelect = (item) => {
        setInput('');
        setSuggestions([]);
        const valueBeforeDash = item.split(' -')[0].trim();
        navigate(`/company/${valueBeforeDash}`);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prev) => (prev < suggestions.length - 1 ? prev + 1 : 0));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : suggestions.length - 1));
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            e.preventDefault();
            handleSelect(suggestions[highlightedIndex]);
        }
    };

    React.useEffect(() => {
        return () => {
            abortController.current.abort(); // Ensure fetch is cancelled on unmount
        };
    }, []);

    return (
        <div id="navbar-search-form" className="mb-0 me-4" ref={componentRef}>
            <div className="input-group">
                <span id="layers-search-component-input-group" className="input-group-text border-1 pe-4">
                    <i className="fe fe-search"></i>
                </span>
                <input
                    id="layers-search-component"
                    className="form-control"
                    type="text"
                    aria-label="Search by Company, Ticker, or CIK"
                    placeholder="Search by Company, Ticker, or CIK"
                    value={input}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
                <div className="list-group suggestions-container" ref={listEl}>
                    {suggestions.map((item, index) => (
                        <div
                            className={`list-group-item list-group-item-action ${highlightedIndex === index ? 'active' : ''}`}
                            key={item}
                            tabIndex="0"
                            onClick={(e) => { e.preventDefault(); handleSelect(item); }}
                            onMouseEnter={() => setHighlightedIndex(index)}
                            ref={el => (index === highlightedIndex ? (listEl.current = el) : null)}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SearchComponent;
