import React, { Component } from 'react';

export class Features extends Component {
    static displayName = Features.name;



    render() {
        return (

            <section className="py-8 py-md-8 border-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4" >


                            <div className="icon text-primary mb-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M0 0h24v24H0z"></path><path d="M12 22a9 9 0 110-18 9 9 0 010 18z" fill="#335EEA" opacity=".3"></path><path d="M11.963 7.5h.085a.5.5 0 01.497.45L13 12.5l3.248 1.856a.5.5 0 01.252.434V15a.382.382 0 01-.482.368l-4.62-1.26a.5.5 0 01-.366-.52l.432-5.626a.5.5 0 01.499-.462z" fill="#335EEA"></path></g></svg>

                            </div>
                            <h3>
                                Historical Excel Statement Models
                            </h3>


                            <p className="text-muted mb-6 mb-md-0">
                                5 Year historical models of all Income Statements, Balance Sheets, and Cashflows
                            </p>

                        </div>
                        <div className="col-12 col-md-4" >


                            <div className="icon text-primary mb-3">

                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M0 0h24v24H0z"></path><rect fill="#335EEA" opacity=".3" x="12" y="4" width="3" height="13" rx="1.5"></rect><rect fill="#335EEA" opacity=".3" x="7" y="9" width="3" height="8" rx="1.5"></rect><path d="M5 19h15a1 1 0 010 2H4a1 1 0 01-1-1V4a1 1 0 112 0v15z" fill="#335EEA"></path><rect fill="#335EEA" opacity=".3" x="17" y="11" width="3" height="6" rx="1.5"></rect></g></svg>

                            </div>
                            <h3>
                                Visual Trend Analysis
                            </h3>


                            <p className="text-muted mb-6 mb-md-0">
                                Jump start your analysis with the latest company filing data visualized in Microsoft Power BI
                            </p>

                        </div>

                        <div className="col-12 col-md-4" >


                            <div className="icon text-primary mb-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M0 0h24v24H0z" /><path d="M17.272 8.685a1 1 0 111.456-1.37l4 4.25a1 1 0 010 1.37l-4 4.25a1 1 0 01-1.456-1.37l3.355-3.565-3.355-3.565zm-10.544 0L3.373 12.25l3.355 3.565a1 1 0 01-1.456 1.37l-4-4.25a1 1 0 010-1.37l4-4.25a1 1 0 011.456 1.37z" fill="#335EEA" /><rect fill="#335EEA" opacity=".3" transform="rotate(15 12 12)" x="11" y="4" width="2" height="16" rx="1" /></g></svg>            </div>

                            <h3>
                                XBRL Data Elements
                            </h3>


                            <p className="text-muted mb-0">
                                Data sourced directly from XBRL filings and elements of each company's 10-Ks and 10-Qs
                            </p>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


