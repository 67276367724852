import React from 'react';

const SubscriptionCard = ({ user }) => (
    <div className="card card-bleed shadow-light-lg mb-6">
        <div className="card-header">
            <div className="row align-items-center">
                <div className="col">


                    <h4 className="mb-0">
                        Current Plan
                    </h4>

                </div>
                <div className="col-auto">


                    <span className="h4 fw-bold">

                        <a className="btn btn-xs btn-primary" href={'https://billing.stripe.com/p/login/test_eVa9B00ohcnNbxS144?prefilled_email=' + user.email}>
                            Manage Plan
                        </a>
                    </span>

                </div>
            </div>
        </div>

        <div className="card-body">


            <div className="row mb-5">
                <div className="col">


                    <h4 className="mb-0">
                        Analyst
                    </h4>

                    <small className="text-gray-700">
                        $18/mo billed monthly
                    </small>

                </div>
                <div className="col-auto">
                </div>
            </div>


            <div className="d-flex">


                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                    <i className="fe fe-check"></i>
                </div>


                <p>
                    <small>Quarterly and Annual Excel Models</small>
                </p>

            </div>

            <div className="d-flex">


                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                    <i className="fe fe-check"></i>
                </div>

                <p>
                    <small>5 Year Historical Data</small>
                </p>

            </div>


            <div className="d-flex">


                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                    <i className="fe fe-check"></i>
                </div>


                <p className="mb-0">
                    <small>Ongoing SEC Filing Data</small>
                </p>

            </div>

        </div>
    </div>
);

export default SubscriptionCard;
