import React, { Component } from 'react';
import LayersDiagram from '../images/LayersDiagram.png';
import LoginButton from '../auth0/LoginButton';
import './LandingPage.css'

export class LandingPage extends Component {
    static displayName = LandingPage.name;

    


    render() {
        return (
            <div>
                <section className="pt-12 pt-md-13">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 col-lg-6 order-md-2">

                                
                                <img src={LayersDiagram} alt="Layers Diagram" className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0"  />
                            </div>
                            <div className="col-12 col-md-7 col-lg-6 order-md-1">


                                <h1 className="display-3 text-center text-md-start">
                                    Public Company Filing Data <span className="text-primary text-green">Modeled in Excel</span>. <span className="text-yellow">Analyzed in Power BI</span>
                                </h1>


                                <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">
                                    Download any company's historical financial data directly into a Microsoft Excel model.  Analyze Aggregate Company Financial Data in Power BI.
                                </p>


                                <div className="text-center text-md-start">
                                    <LoginButton />

                                    <a href="/company/MSFT" className="btn btn-primary-soft mt-2">
                                        See the latest Microsoft ($MSFT) Analysis and Model.
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                </div>
            
        );
    }
}


