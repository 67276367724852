import React from 'react';
import Photo1 from '../images/financial-1.jpg';
import { useAuth0 } from "@auth0/auth0-react";

const RegisterModal = ({ show, onClose }) => {

    const { loginWithRedirect } = useAuth0();

    return (
        show ? (
            <div className="modal fade show" id="modalSigninHorizontal" tabIndex="-1" aria-labelledby="modalSigninHorizontalTitle" aria-modal="true" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="card card-row">
                            <div className="row gx-0">

                                <div className="col-12 col-md-6 bg-cover card-img-start" style={{ backgroundImage: `url(${Photo1})` }}>

                                    <img src={Photo1} alt="Descriptive alt text" className="img-fluid d-md-none invisible" />

                                    <div className="shape shape-end shape-fluid-y text-white d-none d-md-block">
                                        <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M116 0H51v172C76 384 0 517 0 517v173h116V0z" fill="currentColor"></path>
                                        </svg>
                                    </div>

                                </div>


                                <div className="col-12 col-md-6">
                                    <div className="card-body">
                                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>

                                        <h2 className="mb-2 fw-bold text-center" id="modalSigninHorizontalTitle">
                                            Get Started
                                        </h2>
                                        <p className="mb-6 text-center text-body-secondary">
                                            This model download requires a subscription.  Create your account to download this model.
                                        </p>
                                        

                                        <button className="navbar-btn btn btn-sm btn-primary me-1 mb-2 w-100" onClick={() => loginWithRedirect({screen_hint: 'signup'})}>
                                            Create Account <i className="fe fe-arrow-right"></i>
                                        </button>

                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    );
};

export default RegisterModal;
