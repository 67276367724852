import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Notification from '../General/Notification';
import Spinner from '../General/Spinner';
import UserProfile from './UserProfile';
import Sidebar from './Sidebar';
import SubscriptionCard from './SubscriptionCard';
import SubscriptionOffer from './SubscriptionOffer';

export const ProfilePage = () => {
    const { user, isAuthenticated, isLoading, getIdTokenClaims, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [hasSubscription, setHasSubscription] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0); // Used to force re-render


    const checkSubscriptionStatus = async () => {
        try {
            const idTokenClaims = await getIdTokenClaims();
            const appMetadata = idTokenClaims[`https://www.layers.cloud/hasSubscription`];
            if (appMetadata) {
                setHasSubscription(appMetadata === true);
            }
        } catch (error) {
            console.error("Error checking subscription status", error);
        }
    };

    const refreshUserSession = async () => {
        try {
            await getAccessTokenSilently({ ignoreCache: true });
            // You might need to manually update the user state here
            setRefreshKey(oldKey => oldKey + 1);
        } catch (error) {
            // Handle or log error
            // If silent authentication fails, you can fallback to a full login
            loginWithRedirect();
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
            setHasSubscription(true);
            refreshUserSession();
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage("Order canceled -- continue and checkout when you're ready.");
        }

        if (user) {
            checkSubscriptionStatus();
        }
    }, [user, refreshKey]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        isAuthenticated && (
            <div>
                <UserProfile user={user} />
                <main className="pb-8 pb-md-11 mt-md-n6">
                    <div className="container-md">
                        <div className="row">
                            <Sidebar />
                            <div className="col-12 col-md-9">
                                <Notification success={success} message={message} sessionId={sessionId} />
                                {hasSubscription ? (
                                    <SubscriptionCard user={user} />
                                ) : (
                                    <SubscriptionOffer />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    );
};

export default ProfilePage;
