import React, { Component } from 'react';
import MsftLogo from '../images/msft_logo_square.jpg';
import SnowLogo from '../images/snowflake_logo.png';
import HomeDepotLogo from '../images/home_depot_logo.jpg';
import JPMLogo from '../images/jpm_logo.jpg';
import TeslaLogo from '../images/tesla_logo_square.png';
export class CompanyList extends Component {
    
    render() {
        return (

            <section className="pt-8 pt-md-6" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">

                            <div className="btn btn-white btn-rounded-circle shadow mt-n12 mt-md-n14">
                                <i className="fe fe-arrow-down"></i>
                            </div>

                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center mb-3">

                           
                            <h2 className="fw-bold">
                                Download <span className="text-primary">Popular Company Models</span> and Search by Ticker, Company Name, or CIK.
                            </h2>

                            <p></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">

                            <div className="row align-items-center mb-8 aos-init aos-animate">
                                <div className="col-4 col-lg-5">

                                    <img src={MsftLogo} alt="MSFT Logo" className="img-fluid" />

                                </div>
                                <div className="col-8 col-lg-7">

                                    <h3 className="fw-bold mb-2">
                                        <a href="/company/MSFT">Microsoft ($MSFT)</a>
                                    </h3>

                                    <p className="text-gray-700 mb-0">
                                        
                                    </p>

                                </div>
                            </div>

                            <div className="row align-items-center mb-8 aos-init aos-animate">
                                <div className="col-4 col-lg-5">

                                    <img src={HomeDepotLogo} alt="Home Depot Logo" className="img-fluid" />

                                </div>
                                <div className="col-8 col-lg-7">

                                    <h3 className="fw-bold mb-2">
                                        <a href="/company/HD">Home Depot ($HD)</a>
                                    </h3>

                                    <p className="text-gray-700 mb-0">
                                    </p>

                                </div>
                            </div>


                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="row align-items-center mb-8 aos-init aos-animate">
                                <div className="col-4 col-lg-5">

                                    <img src={TeslaLogo} alt="Tesla Logo" className="img-fluid" />

                                </div>
                                <div className="col-8 col-lg-7">

                                    <h3 className="fw-bold mb-2">
                                        <a href="/company/TSLA">Tesla ($TSLA)</a>
                                    </h3>

                                    <p className="text-gray-700 mb-0">
                                    </p>

                                </div>
                            </div>

                            <div className="row align-items-center mb-8 aos-init aos-animate">
                                <div className="col-4 col-lg-5">

                                    <img src={JPMLogo} alt="JP Morgan Chase Logo" className="img-fluid" />

                                </div>
                                <div className="col-8 col-lg-7">

                                    <h3 className="fw-bold mb-2">
                                        <a href="/company/JPM">JPMorgan Chase ($JPM)</a>
                                    </h3>

                                    <p className="text-gray-700 mb-0">
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


