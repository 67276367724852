import React from 'react';

const UserProfile = ({ user }) => (
    <header className="bg-dark pt-9 pb-11 d-none d-md-block pt-12 pt-md-13">
        <div className="container-md">
            <div className="row align-items-center">
                <div className="col">


                    <h1 className="fw-bold text-white mb-2">
                        Profile
                    </h1>


                    <p className="fs-lg text-white-75 mb-0">
                        {user.email}
                    </p>

                </div>
                <div className="col-auto">




                </div>
            </div>
        </div>
    </header>
);

export default UserProfile;