import React, { Component } from 'react';

export class FormExample extends Component {

    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();

        fetch('weatherforecast/test', {
            method: "POST",
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({ amount: 100, currency: this.state.value })
        }).
        then(response => response.text())
        .then(data => {
            this.setState({ text: data, loading: false });
        });
        
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    Name:
                    <input type="text" value={this.state.value} onChange={this.handleChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }
    
}
