import React, { Component } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const Pricing = () => {

    const { loginWithRedirect } = useAuth0();

    return (

        <section className="pt-8 pt-md-8 pb-8 bg-gray-200">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">


                        <h1>
                            Beta Access & Pricing
                        </h1>


                        <p className="lead text-gray-700">
                            Unlock every company's filings and models with individual monthly licensing.  Don't see what you need here?  Please reach out for custom features and support.
                        </p>




                    </div>


                </div>

                <div className="row gx-4">
                    <div className="col-12 col-md-6">


                        <div className="card shadow-lg mb-6 mb-md-0">
                            <div className="card-body">


                                <div className="text-center mb-3">
                                    <span className="badge rounded-pill bg-primary-soft">
                                        <span className="h6 text-uppercase">Analyst (BETA)</span>
                                    </span>
                                </div>


                                <div className="d-flex justify-content-center">
                                    <span className="h2 mb-0 mt-2">$</span>
                                    <span className="price display-2 mb-0">18</span>
                                    <span className="h2 align-self-end mb-1">/mo</span>
                                </div>


                                <p className="text-center text-muted mb-5">
                                    per seat
                                </p>


                                <div className="d-flex">


                                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </div>


                                    <p>
                                        Quarterly and Annual Excel Models (BETA)
                                    </p>

                                </div>
                                <div className="d-flex">


                                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </div>


                                    <p>
                                        5 Year Historical Data
                                    </p>

                                </div>
                                <div className="d-flex">


                                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </div>

                                    <p>
                                        Ongoing SEC Filing Data
                                    </p>

                                </div>
                                <div className="d-flex">


                                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </div>


                                    <p>
                                        Power BI Dashboard and Report Access (BETA)
                                    </p>

                                </div>


                                <button className="navbar-btn btn btn-sm btn-primary me-1 mb-2 w-100" onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>Get Started <i className="fe fe-arrow-right"></i></button>

                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6">

                        <div className="card shadow-lg mb-6 mb-md-0">
                            <div className="card-body">


                                <div className="text-center mb-3">
                                    <span className="badge rounded-pill bg-primary-soft">
                                        <span className="h6 text-uppercase">Commercial</span>
                                    </span>
                                </div>


                                <div className="d-flex justify-content-center">

                                    <span className="price display-4 mb-0">Commercial</span>
                                </div>


                                <p className="text-center text-muted mb-5">
                                    Custom Licensing
                                </p>


                                <div className="d-flex">


                                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </div>


                                    <p>
                                        Custom Data & Company Metrics
                                    </p>

                                </div>
                                
                                <div className="d-flex">


                                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </div>

                                    <p>
                                        Data Warehouse Access
                                    </p>

                                </div>



                                <a href="mailto:aleroy@layers.cloud?subject=Layers.cloud" className="btn w-100 btn-secondary">
                                    Get In Touch <i className="fe fe-arrow-right ms-3"></i>
                                </a>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>

    );

}

export default Pricing;

