import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import ProfileNav from './ProfileNav';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const ProfileNavBar = () => {
    const { user, isAuthenticated } = useAuth0();

    return (
        <div>
            {isAuthenticated
                ? <div>
                    <ProfileNav user={user} />
                    <Link user={user} className="navbar-btn btn btn-sm btn-primary me-2" to="/profile">Profile</Link>
                    <LogoutButton />
                </div>
                : <LoginButton />}
        </div>


    );
};


export default ProfileNavBar;