import React, { Component } from 'react';
import Photo1 from '../images/financial-1.jpg';
import Photo2 from '../images/msft_balance_sheet.PNG';
import Photo3 from '../images/msft_power_bi.PNG';

export class StatsBI extends Component {

    render() {
        return (

            <section className="pt-8 pb-8 pt-md-8 bg-gray-200">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 col-lg-6 order-md-1">
                            <h2>
                                <span className="text-primary">Visualize Metrics and Company Trends</span>
                            </h2>

                            <p className="fs-lg text-gray-700 mb-6">
                                Quickly visualize company financials and trends with a suite of online, interactive Microsoft Power BI dashboards and reports.
                            </p>

                            
                            
                        </div>
                        <div className="col-12 col-md-7 col-lg-6 order-md-2">

                            <img src={Photo3} alt="Layers Power BI" className="img-fluid mb-6 mb-md-0" />

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}


